import React from 'react'
import { Button, TextInput } from "flowbite-react";
import { onFieldChange, onFormSubmit } from '../../utility/form'
import utilStr from '../../utility/str'

const Login = ({ handleSubmit, handleChange, rec, method }) => {

	const renderInput = () => {
		switch (method) {
			case 'email':
				return (
					<div>
						<TextInput
							color="info"
							className="w-full border-jade-500 bg-jade-50 text-gray-900 placeholder-gray-700 focus:border-jade-500 focus:ring-jade-500 dark:border-jade-400 dark:bg-jade-100 dark:focus:border-jade-500 dark:focus:ring-jade-500"
							name="email"
							onChange={e => onFieldChange(e, handleChange)}
							placeholder="Enter Email..."
							type="text"
							style={{ borderColor: '#00D37F', outlineColor: '#00D37F'}}
							value={rec.email} />
					</div>
				)
			default:
				return (
					<div>
						<TextInput
							color="info"
							className="w-full border-jade-500 bg-jade-50 placeholder-gray-700 focus:border-jade-500 focus:ring-jade-500 dark:border-jade-400 dark:bg-jade-100 dark:focus:border-jade-500 dark:focus:ring-jade-500"
							name="email"
							onChange={e => onFieldChange(e, handleChange)}
							placeholder="Enter Email..."
							type="text"
							style={{ borderColor: '#00D37F !important', outlineColor: '#00D37F !important'}}
							value={rec.email} />
					</div>
				)
		}
	}

	let isValidDetail = false
	if (method === 'phone') {
		if (utilStr.checkValidPhone(rec.phone)) {
			isValidDetail = true
		}
	} else {
		if (utilStr.checkValidEmail(rec.email)) {
			isValidDetail = true
		}
	}

	return (
		<div className="relative min-h-screen max-h-screen overflow-hidden flex transition-all duration-500 ease-in-out">
			<div className="w-full bg-gray-100 flex items-center justify-center">
				<div className="flex flex-col items-center text-center lg:w-1/2 xl:w-1/3">
					<div className="bg-white mx-3 md:mx-0 px-8 rounded-lg shadow-lg md:px-10 py-10 relative">
						<div className="flex justify-center items-center">
							<img alt="GloveBox" className="mb-4 h-8 mr-2" src="/img/glovebox-logo.svg" />
						</div>
						<p className="text-gray-800 text-3xl leading-9 font-extrabold text-center mb-3">
							Sign in to your account
						</p>
						<form className="" onSubmit={e => onFormSubmit(e, handleSubmit)}>
							{renderInput()}
							<div className="mt-4 flex items-center justify-center">
								<Button color="primary" type="submit" className="w-full bg-jade-700 text-white" disabled={!isValidDetail}>Launch My Account</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login