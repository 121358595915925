import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Card } from "flowbite-react";
import NewToggle from '../NewToggle'
import model from '../../model'

const AgencyActionsCard = ({ recState, agencyId, navigate }) => {
    const featureFlags = useSelector(state => model.selector('agency.featureFlagsGet', { state, agencyId }))
    const featureFlag = featureFlags?.find(flag => flag.feature_flag.id === 'client_email_rollout');
    const [enabled, setEnabled] = useState(featureFlag ? featureFlag.feature_flag.enabled : false);    
    const [agencyEnabled, setAgencyEnabled] = useState(recState.enabled);
    const hqUrl = model.helper('agency.getHijackUrl', recState)

    useEffect(() => {
        const featureFlag = featureFlags?.find(flag => flag.feature_flag.id === 'client_email_rollout');
        if (featureFlag) {
            setEnabled(featureFlag.feature_flag.enabled);
        }
    }, [featureFlags]);
    

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('agency.getById', { agencyId })
                await model.action('agency.getFeatureFlags', { agencyID: agencyId })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                await model.action('app.setError', e)
            }
        })();
    }, [agencyId]);

    useEffect(() => {
        setAgencyEnabled(recState.enabled);
    }, [recState.enabled]);

    const toggleFeatureFlag = async (value) => {
        try {
            await model.action('app.setLoading', true)
            await model.action('agency.addFeatureFlag', { agencyID: agencyId, featureFlag: 'client_email_rollout', enabled: value });
            await model.action('agency.getFeatureFlags', { agencyID: agencyId })
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const enableDisableAgency = async () => {
        try {
            await model.action('app.setLoading', true);
            await model.action('agency.saveAgencyEnabled', recState);
            await model.action('agency.getById', { agencyId });
            setAgencyEnabled(prevState => !prevState);
            await model.action('app.setLoading', false);
            navigate(`/agency/${agencyId}`);
        } catch (e) {
            await model.action('app.setLoading', false);
            await model.action('app.setError', e);
        }
    };

    return (
        <Card>
            <div className="flow-root">
                <h3 className="text-xl font-bold text-black dark:text-white">Agency Actions</h3>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Enable/Disable Agency
                                </span>
                            </div>
                            <form className="inline-flex items-center">
                                <NewToggle enabled={agencyEnabled} onToggle={enableDisableAgency} setEnabled={setAgencyEnabled} />
                            </form>
                        </div>
                    </li>
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Client Email Rollout
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <NewToggle enabled={enabled} onToggle={toggleFeatureFlag} setEnabled={setEnabled} />
                            </div>
                        </div>
                    </li>
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Agency HQ
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <Button color="light" className="hover:no-underline hover:text-jade-700" href={hqUrl} target="_blank" rel="noreferrer">
                                    Launch
                                </Button>
                            </div>
                        </div>
                    </li>
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Download Logo
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <Button color="light" className="hover:no-underline hover:text-jade-700" href={recState && recState.logo + "?force=true"} target="_blank" rel="noopener noreferrer" download>
                                    Download
                                </Button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </Card>
    );
};

export default AgencyActionsCard;