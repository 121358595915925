import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createBrowserHistory } from 'history';
import model from '../../model'
import { onFieldChange } from '../../utility/form'
import { useNavigate } from 'react-router-dom'
import AllCarriersTable from './AllCarriersTable'
import { Breadcrumb, Button, Label, TextInput } from "flowbite-react";
import { HiHome, HiPlus } from "react-icons/hi";
import NavbarSidebarLayout from "../NewNav";

const Carriers = () => {
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const listActive = useSelector(state => model.selector('carrier.listActive', state));
  const [search, setSearch] = useState('');
  const link = '/carriers/add';

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('carrier.getList')
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })();
  }, []);

  useEffect(() => {
    if (history.action === 'POP') {
      const lastSearch = sessionStorage.getItem('searchCarriers') || ''
      setSearch(lastSearch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSearch = (list) => {
    if (!list) return []
    const searchLower = search.toLowerCase()
    return list.filter((item) => item.name.toLowerCase().includes(searchLower))
  }

  const handleChange = (name, value) => {
    sessionStorage.setItem('searchCarriers', value);
    setSearch(value);
  };

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/carriers">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Carriers</span>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Carriers
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <div className="lg:pr-3">
                <Label htmlFor="users-search" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    color="info"
                    id="search"
                    autoFocus
                    onChange={e => onFieldChange(e, handleChange)}
                    name="search"
                    placeholder="search for a carrier"
                    type="text"
                    value={search}
                  />
                </div>
              </div>
            </div>
            <div className="ml-auto mb-3 flex items-center space-x-2 sm:space-x-3">
              <Button color="primary" onClick={() => navigate(link)}>
                <div className="flex items-center gap-x-3">
                  <HiPlus className="text-xl" />
                  <span>Add Carrier</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <AllCarriersTable
                currentCarriers={filterSearch(listActive)}
                navigate={navigate}
              />
            </div>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  )
}

export default Carriers;

