import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import model from '../../model'
import { Breadcrumb, Button, Label, TextInput } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import NavbarSidebarLayout from "../NewNav";
import SearchTable from './SearchTable'

const PolicyHolders = () => {
  const navigate = useNavigate()
  const policyHolder = useSelector(state => model.selector('user.policyHolder', state))
  const prevQuery = useSelector(state => model.selector('user.getSearchQuery', state))
  const [hits, setHits] = useState(0)
  const [policyInput, setPolicyInput] = useState('');
  const [results, setResults] = useState([]);
  const [hidden, setHidden] = useState(true);
  let searchTimeout = null;

  useEffect(() => {
    (async () => {
      if (policyHolder) {
        setHidden(false)
        setHits(policyHolder.length)
        setResults(policyHolder)

        if (prevQuery) {
          setPolicyInput(prevQuery)
        }
      }
    })()
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
    }
  }, [policyHolder, prevQuery, searchTimeout])

  const search = async () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout)
    }
    searchTimeout = setTimeout(async () => {
      searchTimeout = null
      try {
        const getPolicy = await model.action('user.getPolicyHolder', policyInput);
        const results = getPolicy.results;

        setHidden(false)
        setHits(results.length)
        setResults(results);
      } catch (e) {
        await model.action('app.setError', e)
      }
    }, 200)
  }

  const newHandleChange = (name, value) => {
    setPolicyInput(value)
  }

  const handleSubmit = () => {
    search();
  }

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Policyholder Search
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <form className="lg:pr-3 flex" onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <Label htmlFor="policyInput" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    color="info"
                    id="policyInput"
                    name="policyInput"
                    onChange={e => onFieldChange(e, newHandleChange)}
                    placeholder="Type here..."
                    type="text"
                    value={policyInput}
                  />
                </div>
                <div className="ml-3 flex justify-center items-center">
                  <Button color="primary" type="submit">
                    Search
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <span className="text-base font-normal text-gray-500 dark:text-gray-400">
            Searching for: {policyInput}
          </span>
        </div>
      </div>
      <div className="flex flex-col mb-10">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <SearchTable
                results={results}
                hits={hits}
                hidden={hidden}
                searchQuery={policyInput}
                navigate={navigate}
              />
            </div>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  )
}

export default PolicyHolders

