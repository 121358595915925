import React, { useState } from 'react'
import { Button, Card, TextInput } from "flowbite-react";
import { HiCloudUpload } from "react-icons/hi";
import model from '../../model'
import { onFieldChange, onFormSubmit } from '../../utility/form'

const AgencyProfileCard = ({ rec }) => {
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({
        image: '',
        theme: (rec && rec.theme) || 'light'
    })

    const handleChange = (name, value) => {
        const formData = {
            ...form,
            [name]: value,
        }

        setForm(formData);
    }

    const handleFileShow = (e) => {
        setShow(!show)
    }

    const handleSubmit = async () => {
        await model.action('app.setError', null)
        try {
            await model.action('app.setLoading', true)
            if (form.image) {
                await model.action('agency.uploadImage', { agencyId: rec.id, image: form.image })
            }
            setShow(false)
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    return (
        <Card>
            <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
                <img
                    alt=""
                    src={rec && rec.logo}
                    className={`mb-4 max-h-32 max-w-44 rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0 ${rec && rec.theme === 'dark' ? 'bg-gray-900' : ''}`}
                />
                <div>
                    <h3 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                        {rec && rec.name}
                    </h3>
                    <div className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Agency ID: {rec && rec.id}
                    </div>
                    <Button
                        color="primary"
                        onClick={handleFileShow}
                    >
                        <HiCloudUpload className="mr-2" />
                        Change Logo
                    </Button>
                </div>
            </div>
            {show &&
                <form onSubmit={e => onFormSubmit(e, handleSubmit)}>
                    <TextInput
                        color="info"
                        className=""
                        name="image"
                        accept=".jpg,.png,.gif,image/jpeg,image/gif,image/png"
                        onChange={e => onFieldChange(e, handleChange)}
                        type="file"
                    >
                    </TextInput>
                    <div className="flex items-center justify-center mt-4">
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            }
        </Card>
    );
};

export default AgencyProfileCard;