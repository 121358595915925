import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { onFormSubmit } from '../../utility/form'
import model from '../../model'
import { Button, Card, Label, TextInput, Select } from "flowbite-react";

const CarrierInformationCard = ({ rec, recCommercial, navigate, isCommercial }) => {
    const user = useSelector(state => model.selector('user.rec', state));
    const [createPersonal, setCreatePersonal] = useState(!rec && recCommercial ? true : false);
    const [createCommercial, setCreateCommercial] = useState(!recCommercial && rec ? true : false);
    const [recState, setRecState] = useState({
        name: recCommercial?.name || '',
        id: '',
        theme: recCommercial?.theme || 'light',
        enabled: '',
        division: 'personal',
        groupID: recCommercial?.groupID,
        registerEntryURL: '',
        registerExitURL: '',
        forgotPasswordURL: '',
        loginURL: '',
        payBillURL: '',
        claimsURL: '',
        telematicsURL: '',
        roadsideServiceURL: '',
        roadsideServicePhone: '',
    });
    const [commercialState, setCommercialState] = useState({
        name: rec?.name || '',
        id: '',
        theme: rec?.theme || 'light',
        enabled: '',
        division: 'commercial',
        groupID: rec?.groupID,
        registerEntryURL: '',
        registerExitURL: '',
        forgotPasswordURL: '',
        loginURL: '',
        payBillURL: '',
        claimsURL: '',
        telematicsURL: '',
        roadsideServiceURL: '',
        roadsideServicePhone: '',
    });

    useEffect(() => {
        setRecState(rec ? rec : {
            name: recCommercial?.name || '',
            id: '',
            theme: recCommercial?.theme || 'light',
            enabled: '',
            division: 'personal',
            groupID: recCommercial?.groupID,
            registerEntryURL: '',
            registerExitURL: '',
            forgotPasswordURL: '',
            loginURL: '',
            payBillURL: '',
            claimsURL: '',
            telematicsURL: '',
            roadsideServiceURL: '',
            roadsideServicePhone: '',
        });
        setCommercialState(recCommercial ? recCommercial : {
            name: rec?.name || '',
            id: '',
            theme: rec?.theme || 'light',
            enabled: '',
            division: 'commercial',
            groupID: rec?.groupID,
            registerEntryURL: '',
            registerExitURL: '',
            forgotPasswordURL: '',
            loginURL: '',
            payBillURL: '',
            claimsURL: '',
            telematicsURL: '',
            roadsideServiceURL: '',
            roadsideServicePhone: '',
        });
        setCreatePersonal(!rec && recCommercial ? true : false);
        setCreateCommercial(!recCommercial && rec ? true : false);
    }, [rec, recCommercial]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecState((currentState) => ({
            ...currentState,
            [name]: name === "enabled" ? value === "true" : value,
        }));
    };

    const handleCommercialChange = (e) => {
        const { name, value } = e.target;
        setCommercialState((currentState) => ({
            ...currentState,
            [name]: name === "enabled" ? value === "true" : value,
        }));
    };

    const handleSubmit = async () => {
        let saveRec = { ...recState }
        Object.keys(saveRec).forEach((i) => {
            if (saveRec[i] === '') saveRec[i] = null
        })

        let saveCommercial = commercialState ? { ...commercialState } : null
        if (saveCommercial) {
            Object.keys(saveCommercial).forEach((i) => {
                if (saveCommercial[i] === '') saveCommercial[i] = null
            })
        }

        await model.action('app.setError', null)
        try {
            await model.action('app.setLoading', true)

            if (createPersonal && !isCommercialChanged) {
                await model.action('carrier.add', saveRec)
                setCreatePersonal(false)
                navigate(`/personal/${saveRec.id}/commercial/${recCommercial.id}`)
            }

            if (createCommercial && !isRecChanged) {
                await model.action('carrier.add', saveCommercial)
                setCreateCommercial(false)
                navigate(`/personal/${rec.id}/commercial/${saveCommercial.id}`)
            }

            if (isCommercialChanged) {
                await model.action('carrier.save', { commercial: saveCommercial })
            }

            if (isRecChanged) {
                await model.action('carrier.save', { rec: saveRec })
            }
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const isRecChanged = rec && Object.keys(recState).find(key => {
        if (key === 'updated' || key === 'image' || key === 'logo') return false

        if (rec && rec[key] !== recState[key]) {
            return true
        }
        return false
    }) !== undefined

    const isCommercialChanged = recCommercial && Object.keys(commercialState).find(key => {
        if (key === 'updated' || key === 'image' || key === 'logo') return false

        if (recCommercial && recCommercial[key] !== commercialState[key]) {
            return true
        }
        return false
    }) !== undefined;

    const inputProps = isCommercial
        ? { value: commercialState, onChange: handleCommercialChange }
        : { value: recState, onChange: handleChange };

    return (
        <Card>
            <h3 className="mb-4 text-xl font-bold text-black dark:text-white">
                Carrier Details
            </h3>
            <form onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <div className="grid grid-cols-6 gap-6">
                    {!isCommercial && createPersonal ? (
                        <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                            <Label>Carrier ID</Label>
                            <TextInput
                                color="info"
                                name="id"
                                onChange={inputProps.onChange}
                                placeholder="Enter unique carrier ID"
                                type="text"
                                value={inputProps.value.id}
                            />
                        </div>
                    ) : <div className="hidden" />}
                    {isCommercial && createCommercial ? (
                        <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                            <Label>Carrier ID</Label>
                            <TextInput
                                color="info"
                                name="id"
                                onChange={inputProps.onChange}
                                placeholder="Enter unique carrier ID"
                                type="text"
                                value={inputProps.value.id}
                            />
                        </div>
                    ) : <div className="hidden" />}
                    {user && user.role && user.role.id === "admin" ?
                        <>
                            {!isCommercial ?
                                <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                    <Label htmlFor="enabled">Enabled</Label>
                                    <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F' }} id="enabled" name="enabled" onChange={inputProps.onChange} value={String(inputProps.value.enabled)}>
                                        <option value="true">Enabled</option>
                                        <option value="false">Disabled</option>
                                    </Select>
                                </div>
                                :
                                <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                    <Label htmlFor="enabled">Enabled</Label>
                                    <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F' }} id="enabled" name="enabled" onChange={inputProps.onChange} value={String(inputProps.value.enabled)}>
                                        <option value="true">Enabled</option>
                                        <option value="false">Disabled</option>
                                    </Select>
                                </div>
                            }
                        </>
                        :
                        <>
                            {!isCommercial ?
                                <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <Label htmlFor="enabled">Enabled</Label>
                                <TextInput
                                    color="info"
                                    id="enabled"
                                    name="enabled"
                                    placeholder="ENABLED"
                                    value={recState.enabled ? 'ENABLED' : 'DISABLED'}
                                    readOnly
                                />
                            </div>
                            :
                            <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                                <Label htmlFor="enabled">Enabled</Label>
                                <TextInput
                                    color="info"
                                    id="enabled"
                                    name="enabled"
                                    placeholder="ENABLED"
                                    value={commercialState.enabled ? 'ENABLED' : 'DISABLED'}
                                    readOnly
                                />
                            </div>
                            }
                        </>}
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="name">Carrier Name</Label>
                        <TextInput
                            color="info"
                            id="name"
                            name="name"
                            value={inputProps.value.name}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="last-name">Theme</Label>
                        <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F' }} id="theme" name="theme" value={inputProps.value.theme}
                            onChange={inputProps.onChange}>
                            <option value="light">Light</option>
                            <option value="dark">Dark</option>
                        </Select>
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="regiterEntryURL">Register Entry URL</Label>
                        <TextInput
                            color="info"
                            id="registerEntryURL"
                            name="registerEntryURL"
                            placeholder="Entry URL"
                            value={inputProps.value.registerEntryURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="registerExitURL">Register Exit URL</Label>
                        <TextInput
                            color="info"
                            id="registerExitURL"
                            name="registerExitURL"
                            placeholder="Exit URL"
                            value={inputProps.value.registerExitURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="forgotPasswordURL">Forgot Password URL</Label>
                        <TextInput
                            color="info"
                            id="forgotPasswordURL"
                            name="forgotPasswordURL"
                            placeholder="Forgot Password URL"
                            value={inputProps.value.forgotPasswordURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="loginURL">Login URL</Label>
                        <TextInput
                            color="info"
                            id="loginURL"
                            name="loginURL"
                            placeholder="Login URL"
                            value={inputProps.value.loginURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="payBillURL">Pay Bill URL</Label>
                        <TextInput
                            color="info"
                            id="payBillURL"
                            name="payBillURL"
                            placeholder="Pay Bill URL"
                            value={inputProps.value.payBillURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="claimsURL">Claims URL</Label>
                        <TextInput
                            color="info"
                            id="claimsURL"
                            name="claimsURL"
                            placeholder="claimsURL"
                            value={inputProps.value.claimsURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="telematicsURL">Telematics URL</Label>
                        <TextInput
                            color="info"
                            id="telematicsURL"
                            name="telematicsURL"
                            placeholder="Telematics URL"
                            value={inputProps.value.telematicsURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="roadsideServiceURL">Roadside Service URL</Label>
                        <TextInput
                            color="info"
                            id="roadsideServiceURL"
                            name="roadsideServiceURL"
                            placeholder='https://carrier.com/roadsideServiceURL'
                            value={inputProps.value.roadsideServiceURL}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="roadsideServicePhone">Roadside Service Phone</Label>
                        <TextInput
                            color="info"
                            id="roadsideServicePhone"
                            name="roadsideServicePhone"
                            placeholder="Enter Roadside Service Phone"
                            value={inputProps.value.roadsideServicePhone}
                            onChange={inputProps.onChange}
                        />
                    </div>
                    <div className="flex items-center justify-center col-span-6">
                        <Button type="submit" color="primary">Save</Button>
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default CarrierInformationCard;