import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loader from './Loader'
import ErrorC from './Error'
import RouteC from './Route'
import Auth from '../Auth'
import model from '../../model'

const Page = () => {
	const user = useSelector(state => model.selector('user.rec', state));
	const error = useSelector(state => model.selector('app.error', state));
	const loading = useSelector(state => model.selector('app.loading', state));
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		(async () => {
			try {
				await model.action('app.setLoading', true)
				await model.action('app.init')
				setLoaded(true)
				await model.action('app.setLoading', false)
			} catch (e) {
				setLoaded(true)
				await model.action('app.setLoading', false)
				await model.action('app.setError', e)
			}
		})();
	}, []);

	const handleErrorClose = () => {
		model.action('app.setError', null)
	}

	return (
		<>
			{!loaded ? <div /> :
				<Router>
					{loading && <Loader />}
					{!model.helper('user.isAdmin', user) ?
							<Auth methodType="email" />
						:
						<RouteC />
					}
					<ErrorC handleClose={handleErrorClose} message={error} />
				</Router>
			}
		</>
	)
}

export default Page;
