import React from 'react'
import utilStr from '../../utility/str'
import { Card, Label, TextInput } from "flowbite-react";

const GeneralInformationCard = ({ userPageInfo }) => {
    return (
        <Card>
            <h3 className="mb-4 text-xl font-bold text-black dark:text-white">
                General information
            </h3>
            <form action="#">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="first-name">First Name</Label>
                        <TextInput
                            color="info"
                            id="first-name"
                            name="first-name"
                            value={userPageInfo.firstName}
                            placeholder="First Name"
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="last-name">Last Name</Label>
                        <TextInput
                            color="info"
                            id="last-name"
                            name="last-name"
                            placeholder="Last Name"
                            value={userPageInfo.lastName}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="country">Email</Label>
                        <TextInput
                            color="info"

                            id="country"
                            name="country"
                            placeholder="No Email for Policyholder"
                            value={userPageInfo.email}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="city">Phone Number</Label>
                        <TextInput
                            color="info"

                            id="city"
                            name="city"
                            placeholder="San Francisco"
                            value={`${utilStr.PhoneFormattedPolicyHolder(userPageInfo.phone)}`}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="address">SMS Opt In</Label>
                        <TextInput
                            color="info"
                            id="address"
                            name="address"
                            placeholder="N/A"
                            value={userPageInfo.smsOptIn === true ? 'Yes' : 'No'}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="organization">Communication Preference</Label>
                        <TextInput
                            color="info"
                            id="organization"
                            name="organization"
                            placeholder="Company name"
                            value={userPageInfo.communicationPreference}
                            readOnly
                        />
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default GeneralInformationCard;