import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import model from '../../model'
import AllAgenciesTable from './AllAgenciesTable'
import { onFieldChange } from '../../utility/form'
import { Breadcrumb, Button, Label, TextInput } from "flowbite-react";
import { HiHome, HiPlus, HiTrash } from "react-icons/hi";
import NewToggle from '../NewToggle';
import NavbarSidebarLayout from "../NewNav";
import Pagination from './Pagination';

const Agencies = () => {
  const navigate = useNavigate();
  const all = useSelector((state) => model.selector("agency.all", state));
  const filterState = useSelector((state) => model.selector("agency.filterEnabled", state));
  const prevSearchResult = useSelector((state) => model.selector("agency.prevAgencySearch", state));
  const prevQuery = useSelector((state) => model.selector("agency.prevQuerySearch", state));
  const [search, setSearch] = useState(prevQuery || "");
  const [agencies, setAgencies] = useState([]);
  const [enableFilter, setEnableFilter] = useState(filterState?.filterEnabled || true);
  const [agenciesPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const link = "/agencies/add";

  const filterList = useCallback(
    (list) => {
      if (!list) return [];

      return enableFilter
        ? list.filter((item) => item.enabled).sort((a, b) => (a.name > b.name ? 1 : -1))
        : list.filter((item) => !item.enabled).sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    [enableFilter]
  );

  const clearSearch = (list) => {
    if (!list) return [];
    setSearch("");
    const filtered = filterList(list);
    model.action("agency.clearSearchResults", filtered);
    setAgencies(filtered);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!search) {
      setAgencies(filterList(all));
      clearSearch(all);
      return;
    }

    const searchResults = await model.action('agency.searchAgency', search);
    setAgencies(filterList(searchResults));
  };

  const handleChange = (name, value) => {
    sessionStorage.setItem('searchAgencies', value);
    setSearch(value);
  }


  const enabledDisabled = async () => {
    await model.action("agency.toggleAgentFilter");
    setEnableFilter((prev) => !prev);
    setAgencies(filterList(all));
  };

  useEffect(() => {
    const initialize = async () => {
      if (prevSearchResult && prevQuery) {
        setSearch(prevQuery);
        setAgencies(filterList(prevSearchResult));
      } else {
        await model.action("app.setLoading", true);
        const allAgencies = Object.values(await model.action("agency.getAll"));
        setAgencies(filterList(allAgencies));
        await model.action("app.setLoading", false);
      }
    };

    initialize();
  }, [prevSearchResult, prevQuery, filterList]);

  const indexOfLastAgency = currentPage * agenciesPerPage;
  const indexOfFirstAgency = indexOfLastAgency - agenciesPerPage;
  const currentAgencies = agencies.slice(indexOfFirstAgency, indexOfLastAgency);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <NavbarSidebarLayout isFooter={false}>
      <div className="block items-center justify-between border-b border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex">
        <div className="mb-1 w-full">
          <div className="mb-4">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Agencies
            </h1>
          </div>
          <div className="sm:flex">
            <div className="mb-3 hidden items-center dark:divide-gray-700 sm:mb-0 sm:flex sm:divide-x sm:divide-gray-100">
              <form className="lg:pr-3" onSubmit={submitForm}>
                <Label htmlFor="users-search" className="sr-only">
                  Search
                </Label>
                <div className="relative mt-1 lg:w-64 xl:w-96">
                  <TextInput
                    id="search"
                    autoFocus
                    onChange={e => onFieldChange(e, handleChange)}
                    name="search"
                    placeholder="search for an agency by name"
                    type="text"
                    className=""
                    color="info"
                    value={search}
                  />
                </div>
              </form>
              <div className="mt-2 flex space-x-1 pl-0 sm:mt-0 sm:pl-2">
                <div
                  onClick={() => clearSearch(all)}
                  className="inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span className="sr-only">Delete</span>
                  <HiTrash className="text-2xl" />
                </div>
                <div className="mt-1">
                  <NewToggle onToggle={enabledDisabled} enabled={enableFilter} setEnabled={setEnableFilter} />
                </div>
              </div>
            </div>
            <div className="ml-auto mb-3 flex items-center space-x-2 sm:space-x-3">
              <Button color="primary" onClick={() => navigate(link)}>
                <div className="flex items-center gap-x-3">
                  <HiPlus className="text-xl" />
                  <span>Add Agency</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <AllAgenciesTable
                currentAgencies={currentAgencies}
                navigate={navigate}
              />
            </div>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        agenciesPerPage={agenciesPerPage}
        totalAgencies={agencies.length}
        paginate={paginate}
      />
    </NavbarSidebarLayout>
  )
};

export default Agencies;