import React from 'react'
import { Card } from "flowbite-react";

const CarrierActionsCard = () => {
    return (
        <Card>
            <div className="flow-root">
                <h3 className="text-xl font-bold text-black dark:text-white">Carrier Actions</h3>
                <div className="flex justify-center items-center">
                    <h4 className="text-black font-bold mt-3">Coming Soon!!</h4>
                </div>
            </div>
        </Card>
    );
};

export default CarrierActionsCard;