import React from 'react'
import { Button, Card } from "flowbite-react";

const AgencyListCard = ({ agencyList, disconnectAgency }) => {
    return (
        <Card>
            <div className="flow-root">
                <h3 className="text-xl font-bold text-black dark:text-white">Connected Agencies</h3>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {agencyList && agencyList.map((agency, index) => {
                        return (
                            <>
                                <li key={index} className="py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-base font-semibold text-gray-900 dark:text-white">
                                                {agency.firstName ? agency.firstName : ''} {agency.lastName ? agency.lastName : ''} {agency.name ? agency.name : ''}
                                            </p>
                                            <p className="truncate text-sm font-normal text-gray-500 dark:text-gray-400">
                                                {agency.agencyID ? `Agency-Agent ID: ${agency.agencyID}-${agency.id}` : `Agency ID: ${agency.id}`}
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center">
                                            <Button color="light" onClick={e => disconnectAgency({ agencyID: agency.agencyID, id: agency.id})}>Disconnect</Button>
                                        </div>
                                    </div>
                                </li>
                            </>
                        )
                    }
                    )}
                </ul>
            </div>
        </Card>
    );
};

export default AgencyListCard;