import React from 'react'
import { Card } from "flowbite-react";

const UserProfileCard = ({ userPageInfo, agency }) => {
    return (
        <Card>
            <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
                <img
                    alt=""
                    src={agency && agency.logo}
                    className={`mb-4 max-h-32 max-w-44 rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0 ${agency && agency.theme === 'dark' ? 'bg-gray-900' : ''}`}
                />
                <div>
                    <h3 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                        {`${userPageInfo.firstName ? userPageInfo.firstName : ''} ${userPageInfo.lastName ? userPageInfo.lastName : ''}`}
                    </h3>
                    <div className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        {userPageInfo.id ? `User ID: ${userPageInfo.id}` : ''}
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default UserProfileCard;