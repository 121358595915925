import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import model from '../../model'

const Logout = () => {
    const user = useSelector(state => model.selector('user.rec', state));

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                await model.action('user.logout', { userId: user.id })
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                await model.action('app.setError', e)
            }
        })()
    })

    return (
        <Navigate to={`/auth`} />
    )
}

export default Logout