import React from 'react'
import { Button, Table } from "flowbite-react";
import model from '../../model';

const AllAgenciesTable = ({ currentAgencies, navigate }) => {
    return (
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
            <Table.Head className="bg-gray-100 dark:bg-gray-700">
                <Table.HeadCell>Logo</Table.HeadCell>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell># of Agents</Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell>Agency Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                {currentAgencies && currentAgencies.length > 0 ? currentAgencies.map((rec, i) => {
                    const agencyId = rec.id
                    const logo = rec.logo
                    const enabled = rec.enabled
                    const name = rec.name
                    const hqUrl = model.helper('agency.getHijackUrl', rec)
                    return (
                        <Table.Row key={i} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                                <img
                                    className={`max-h-16 max-w-16  ${rec && rec.theme === 'dark' ? 'bg-gray-900' : ''} rounded-lg`}
                                    src={logo}
                                    alt="Neil Sims avatar"
                                />
                            </Table.Cell>
                            <Table.Cell className="flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                    <div className="text-base font-semibold text-gray-900 dark:text-white">
                                        {name}
                                    </div>
                                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                        Agency ID: {agencyId}
                                    </div>
                                </div>
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                                {rec.agents.length > 0 ? rec.agents.length : 0}
                            </Table.Cell>
                            <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
                                {enabled === true ? (
                                    <div className="flex items-center">
                                        <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>{" "}
                                        Enabled
                                    </div>
                                ) : (
                                    <div className="flex items-center">
                                        <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-700"></div>{" "}
                                        Disabled
                                    </div>
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                <div className="flex items-center gap-x-3 whitespace-nowrap">
                                    <Button color="primary" onClick={() => navigate(`/agency/${rec.id}`)}>Agency Info</Button>
                                    <Button color="gray" className="hover:no-underline hover:text-jade-700" href={hqUrl} target="_blank" rel="noreferrer">Agency HQ</Button>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )
                })
                    :
                    <Table.Row
                        className="hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                        <Table.Cell colSpan={5} className="p-4 text-base font-normal text-gray-900 dark:text-white">
                            No agencies found
                        </Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    );
};

export default AllAgenciesTable;