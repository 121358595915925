import React from 'react'
import { Button, Card } from "flowbite-react";

const UserActionsCard = ({ agency, handleLaunchPolicyHolder, user, userIdNumber, handleModalShow, userId, handlePurgeModal }) => {
    return (
        <Card>
            <div className="flow-root">
                <h3 className="text-xl font-bold text-black dark:text-white">User Actions</h3>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    HQ Profile
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <Button disabled={!agency ? true : false} onClick={handleLaunchPolicyHolder} color="light" target="_blank" rel="noreferrer">
                                    Launch
                                </Button>
                            </div>
                        </div>
                    </li>
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Delete User
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <Button disabled={user.id === userIdNumber ? true : false} onClick={() => handleModalShow({ userId })} color="light">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </li>
                    <li className="py-4">
                        <div className="flex items-center space-x-4">
                            <div className="min-w-0 flex-1 ml-4">
                                <span className="block truncate text-base font-semibold text-gray-900 dark:text-white">
                                    Purge User
                                </span>
                            </div>
                            <div className="inline-flex items-center">
                                <Button disabled={user.role.id === 'admin' ? false : true} color="light" onClick={() => handlePurgeModal({ userId })}>
                                    Purge
                                </Button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </Card>
    );
};

export default UserActionsCard;