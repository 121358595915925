import classNames from "classnames";
import { Sidebar } from "flowbite-react";
import { useEffect, useState } from "react";
import {
    BuildingStorefrontIcon,
    UserCircleIcon,
    UserIcon,
    BuildingOffice2Icon,
} from '@heroicons/react/24/outline';
import { useSidebarContext } from "../../context/SideBarContext";
import isSmallScreen from "../../helpers/is-small-screen";

const SideBar = function () {
    const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
        useSidebarContext();

    const [currentPage, setCurrentPage] = useState("");

    useEffect(() => {
        const newPage = window.location.pathname;

        setCurrentPage(newPage);
    }, [setCurrentPage]);

    return (
        <div
            className={classNames("lg:!block", {
                hidden: !isSidebarOpenOnSmallScreens,
            })}
        >
            <Sidebar
                aria-label="Sidebar with multi-level dropdown"
                collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
            >
                <div className="flex h-full flex-col justify-between">
                    <div>
                        <Sidebar.Items>
                            <Sidebar.ItemGroup>
                                <Sidebar.Item
                                    color="base"
                                    href="/"
                                    icon={BuildingStorefrontIcon}
                                    className={
                                        "/" === currentPage ? "bg-gray-100 dark:bg-gray-700 hover:text-jade-700 hover:no-underline" : "hover:text-jade-700 hover:no-underline"
                                    }
                                >
                                    Agencies
                                </Sidebar.Item>
                                <Sidebar.Item
                                    color="base"
                                    href="/carriers"
                                    icon={BuildingOffice2Icon}
                                    className={
                                        "/carriers" === currentPage
                                            ? "bg-gray-100 dark:bg-gray-700 hover:text-jade-700 hover:no-underline"
                                            : "hover:text-jade-700 hover:no-underline"
                                    }
                                >
                                    Carriers
                                </Sidebar.Item>
                                <Sidebar.Item
                                    color="base"
                                    href="/users"
                                    icon={UserIcon}
                                    className={
                                        "/users" === currentPage
                                            ? "bg-gray-100 dark:bg-gray-700 hover:text-jade-700 hover:no-underline"
                                            : "hover:text-jade-700 hover:no-underline"
                                    }
                                >
                                    Users
                                </Sidebar.Item>
                                <Sidebar.Item
                                    href="/logout"
                                    icon={UserCircleIcon}
                                    className={
                                        "/logout" === currentPage
                                            ? "bg-gray-100 dark:bg-gray-700 hover:text-jade-700 hover:no-underline"
                                            : "hover:text-jade-700 hover:no-underline"
                                    }
                                >
                                    Logout
                                </Sidebar.Item>
                            </Sidebar.ItemGroup>
                        </Sidebar.Items>
                    </div>
                </div>
            </Sidebar>
        </div>
    );
};

export default SideBar;