const state = {
	all: null,
	list: null,
	recs: {},
	agentLists: {},
	agentRecs: {},
	filterEnabled: false,
	agencySearchResults: null,
	searchQuery: null,
	featureFlags: null
}

export const reducer = (st = state, action) => {
	switch (action.type) {
		case 'AGENCY_AGENT_LIST_SET': {
			const { agencyId, list } = action.payload
			const agentLists = {
				...st.agentLists,
				[agencyId]: list
			}
			return { ...st, agentLists }
		}
		case 'AGENCY_AGENT_REC_SET': {
			const { rec } = action.payload
			const agentRecs = {
				...st.agentRecs,
				[rec.id]: rec
			}
			return { ...st, agentRecs }
		}
		case 'AGENCY_LIST_SET':
			const { list } = action.payload
			return { ...st, list }
		case 'AGENCY_ALL_SET':
			const { all } = action.payload
			return { ...st, all }
		case 'AGENCY_REC_SET': {
			const { rec } = action.payload
			const recs = {
				...st.recs,
				[rec.id]: rec
			}
			return { ...st, recs }
		}
		case 'AGENCY_REC_REMOVE': {
			const { rec } = action.payload
			const recs = {
				...st.recs,
				[rec.id]: null
			}
			return { ...st, recs }
		}
		case 'SEARCH_RESULTS_SET': {
			const { searchResults } = action.payload
			return { ...st, agencySearchResults: searchResults }
		}
		case 'SEARCH_QUERY_SET': {
			const { searchQuery } = action.payload
			return { ...st, searchQuery }
		}
		case 'SEARCH_RESULTS_CLEAR': {
			const { searchResults } = action.payload
			return { ...st, agencySearchResults: searchResults }
		}
		case 'SEARCH_QUERY_CLEAR': {
			const { searchQuery } = action.payload
			return { ...st, searchQuery }
		}
		case 'AGENCY_FILTER_SET':
			const { filterEnabled } = action.payload;
			return { ...st, filterEnabled }
		case 'AGENCY_FEATURE_FLAGS_SET':
			const { agencyID, featureFlags } = action.payload;
			return {
				...st,
				featureFlags: {
					...st.featureFlags,
					[agencyID]: featureFlags
				}
			}

		default:
			return st
	}
}