
import glovebox from '@gloveboxhq/glovebox-sdk-js'
import store from '../../store'
import config from '../../config'
import cookie from '../../utility/cookie'

import model from '../index'

const clearXappCookie = async () => {
	return cookie.remove({
		name: config.xappCookieName,
		domain: config.xappCookieDomain,
		sameSite: 'lax',
		secure: true,
	})
}

const clearUserRec = async () => {
	return localStorage.clear(config.userRecName)
}

const clearUserToken = async () => {
	return localStorage.clear(config.userTokenName)
}

const create = async () => {
	const token = await model.action('app.generateAppToken')

	const res = await glovebox.req({
		endpoint: 'users',
		method: 'post',
		token
	})

	await model.action.setUserRec(res.data)
	await model.action('app.setUserToken', res.token)

	store.dispatch({
		type: 'USER_REC_SET',
		payload: { rec: res.data },
	})
}

const remove = async ({ userId }) => {
	const token = await model.action('app.getUserToken')

	await glovebox.req({
		endpoint: `users/${userId}`,
		method: 'delete',
		token
	})

}

const getUserAgencyRole = async ({ userId, agencyId }) => {
	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `agency-personnel?userID=${userId}`,
		method: 'get',
		token,
	})

	return res.data
}

const getUserId = async () => {
	const rec = await model.action('app.getUserRec')
	if (!rec || !rec.id) return null
	return rec.id
}

const getList = async ({ email, phone }) => {
	const token = await model.action('app.getUserToken')

	// if search is all numbers assume phone, else assume email
	let query = {}

	if (phone) { // is numeric
		query.phone = phone
	}
	if (email) {
		query.email = email
	}
	const res = await glovebox.req({
		endpoint: 'users',
		method: 'get',
		query,
		token
	})

	const list = []
	res.data.forEach(rec => {
		list.push(rec.id)
		store.dispatch({
			type: 'USER_REC_SET',
			payload: { rec },
		})
	})

	store.dispatch({
		type: 'USER_LIST_SET',
		payload: { list },
	})
}

const getLoggedInRec = async () => {
	return await model.action('app.getUserRec')
}

const getPolicyHolder = async (query) => {
	if (!query) throw new Error('users.getPolicyHolder: no query defined');

	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		path: `search/v1/policy_holders`,
		method: 'get',
		query: { q: query },
		token
	});


	store.dispatch({
		type: 'SEARCH_QUERY_SET_POLICY_HOLDER',
		payload: { searchQuery: query },
	})

	store.dispatch({
		type: 'POLICY_HOLDER_SET',
		payload: { rec: res.results },
	})

	return res
}

const getPolicyHolderByDigest = async (digest) => {
	if (!digest) throw new Error('users.getPolicyHolder: no digest defined');

	const token = await model.action('app.getUserToken')
	const encodeDigest = encodeURI(digest)

	const res = await glovebox.req({
		endpoint: `policyholders?digest=${encodeDigest}`,
		method: 'get',
		token
	});

	return res
}

const getRecFromRemote = async (userId) => {
	if (!userId) throw new Error('users.getRecFromRemote: no userId defined')

	const token = await model.action('app.getUserToken')

	const res = await glovebox.req({
		endpoint: `users/${userId}`,
		method: 'get',
		token
	})
	return res.data
}

const getUserLoggedIn = async () => {
	const userToken = model.action('app.getUserToken')
	return userToken ? true : false
}

const getVerified = async () => {
	const rec = await getLoggedInRec()
	if (!rec) return false
	if (rec.verified) return true
	return false
}

const storeXappCookie = async () => {
	const token = await model.action('app.getUserToken')

	cookie.set({
		name: config.xappCookieName,
		value: token,
		domain: config.xappCookieDomain,
		sameSite: 'lax',
		secure: true,
	})
}

const init = async () => {
	const stored = await getLoggedInRec()

	if (!stored) return

	try {
		const rec = await model.action('app.getUserRec')
		if (!rec) return null
		const userId = rec.id
		const updatedRec = await getRecFromRemote(userId)
		if (!updatedRec) {
			return
		}
		model.action('app.setUserRec', updatedRec)
		store.dispatch({
			type: 'USER_AGENT_REC_SET',
			payload: { rec },
		})

		if (model.helper('user.isAdmin', rec)) {
			// store cookie that can work cross app
			storeXappCookie()
		}
	} catch (e) {
		await logout()
		return
	}
}

const join = async ({ email, phone, clear = false }) => {
	const appToken = await model.action('app.generateAppToken')
	const query = phone ? { phone } : { email }
	if (!query) throw new Error('users.join: no email or phone defined')
	
	const loggedIn = await getUserLoggedIn()
	if (loggedIn && clear) {
		// remove current user token
		await clearUserToken()
		await clearUserRec()
	}
	
	let userId = await getUserId()
	
	if (!userId) {
		const resCreate = await glovebox.req({
			endpoint: `users`,
			method: 'post',
			token: appToken
		})
		
		if (!resCreate.token) throw new Error('users.join: no token returned from create user')
		
		await model.action('app.setUserToken', resCreate.token)
		userId = resCreate.data.id
	}
	const token = await model.action('app.getUserToken')

	const resSendVerify = await glovebox.req({
		endpoint: `users/${userId}/verify`,
		method: 'post',
		query,
		token
	})

	return resSendVerify.data
}

const listClear = async () => {
	store.dispatch({
		type: 'USER_LIST_SET',
		payload: { list: [] },
	})
}

const login = async ({ email, phone }) => {
	const query = phone ? { phone } : { email }
	
	if (!query) throw new Error('users.login: no email or phone defined')

	// get logged in user or create account
	const loggedIn = await getUserLoggedIn()
	if (loggedIn) {
		// remove current user token
		await clearUserToken()
		await clearUserRec()
	}

	const res = await glovebox.req({
		endpoint: `users/login?emailTemplate=v2`,
		method: 'post',
		query
	})

	return res.data
}

const getUserIdFromToken = async () => {

	const userToken = await model.action('app.getUserToken')

	if (!userToken) return

	const decoded = window.KJUR.jws.JWS.parse(userToken)
	return decoded.payloadObj.sub
}

const loginVerify = async ({ email, pin, phone }) => {
	if (!pin) throw new Error('users.verify: no pin defined')

	if (!email && !phone) throw new Error('user.verify: no email or phone')


	const query = phone ? { phone, pin } : { email, pin }

	const res = await glovebox.req({
		endpoint: `users/login`,
		method: 'post',
		query,
	})

	if (res.token) await model.action('app.setUserToken', res.token)

	const userId = await getUserIdFromToken()

	const rec = await getRecFromRemote(userId)
	await model.action('app.setUserRec', rec)

	await storeXappCookie()

	// associate pending agency id
	// await model.action('agency.associatePendingAgency', { userId });

	if (!model.helper('user.isAdmin', rec)) throw new Error('Not an admin')

	store.dispatch({
		type: 'USER_AGENT_REC_SET',
		payload: { rec },
	})
}

const logoutUser = async (userId) => {
	const token = await model.action('app.getUserToken')

	await glovebox.req({
		endpoint: `users/${userId}/logout`,
		method: 'post',
		token
	})

	await model.action('app.clearUserToken')
	await clearXappCookie()
	await model.action('app.clearUserRec')

}

const logout = async ({ userId }) => {
	await logoutUser(userId)
	store.dispatch({
		type: 'USER_AGENT_REC_CLEAR',
		payload: {},
	})

	return null
}

const purgeUser = async ({ userId }) => {
	const token = await model.action('app.getUserToken')

	await glovebox.req({
		endpoint: `users/${userId}/erase`,
		method: 'post',
		token
	})

	return;
}

const verify = async ({ email, pin, phone }) => {
	if (!pin) throw new Error('users.verify: no pin defined')

	if (!email && !phone) throw new Error('user.verify: no email or phone')

	const query = phone ? { phone, pin } : { email, pin }

	const userId = await getUserIdFromToken()

	const res = await glovebox.req({
		endpoint: `users/${userId}/verify`,
		method: 'post',
		query,
	})

	if (res.token) await model.action('app.setUserToken', res.token)

	const rec = await getRecFromRemote(userId)
	await model.action('app.setUserRec', rec)

	await storeXappCookie()

	// associate pending agency id
	await model.action('agency.associatePendingAgency', { userId })
	store.dispatch({
		type: 'USER_AGENT_REC_SET',
		payload: { rec },
	})
}

model.registerActions('user', {
	create,
	getList,
	getUserAgencyRole,
	getVerified,
	getUserId,
	getUserIdFromToken,
	getUserLoggedIn,
	getRecFromRemote,
	init,
	join,
	listClear,
	login,
	loginVerify,
	logout,
	purgeUser,
	remove,
	verify,
	getPolicyHolder,
	getPolicyHolderByDigest
})
