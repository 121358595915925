"use client";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export default function PurgeModal({ modal, setModal, handleClose, handleSubmit, headlineText, questionText }) {
    return (
        <>
            <Modal show={modal} size="md" onClose={() => setModal(false)} popup>
                <Modal.Header />
                <Modal.Body>
                    <div className="text-center">
                        <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                        <h3 className="text-lg font-normal text-gray-500 dark:text-gray-400">
                            {headlineText}
                        </h3>
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                            {questionText}
                        </span>
                        <div className="flex justify-center gap-4 pt-5">
                            <Button color="failure" onClick={handleSubmit}>
                                {"Yes, I'm sure"}
                            </Button>
                            <Button color="light" onClick={handleClose}>
                                No, cancel
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
