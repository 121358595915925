import React from 'react'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import { Button, Card, Label, Select, TextInput } from "flowbite-react";

const AddCarrierCard = ({ handleSubmit, handleChange, rec }) => {
    return (
        <Card>
            <h3 className="mb-4 text-xl font-bold text-black dark:text-white">
                Carrier Details
            </h3>
            <form onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="id">Carrier ID</Label>
                        <TextInput
                            id="id"
                            color="info"
                            name="id"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Carrier ID"
                            type="text"
                            value={rec.id}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="groupID">Group ID</Label>
                        <TextInput
                            color="info"
                            id="groupID"
                            name="groupID"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="ID to Group Carriers"
                            type="text"
                            value={rec.groupID}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="name">Carrier Name</Label>
                        <TextInput
                            color="info"
                            id="name"
                            name="name"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Carrier name"
                            type="text"
                            value={rec.name}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="division">Carrier Division</Label>
                        <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F'}} id="division" name="division" onChange={e => onFieldChange(e, handleChange)} value={rec.division}>
                            <option value="personal">Personal</option>
                            <option value="commercial">Commercial</option>
                        </Select>
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="registerEntryURL">Register Entry URL</Label>
                        <TextInput
                            color="info"
                            id="registerEntryURL"
                            name="registerEntryURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/register"
                            type="text"
                            value={rec.registerEntryURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="registerExitURL">Register Exit URL</Label>
                        <TextInput
                            color="info"
                            id="registerExitURL"
                            name="registerExitURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/register/done"
                            type="text"
                            value={rec.registerExitURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="theme">Theme</Label>
                        <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F'}} className="focus:ring-jade-500 focus:border-jade-500" id="theme" name="theme" onChange={e => onFieldChange(e, handleChange)} value={rec.theme}>
                            <option value="light">Light</option>
                            <option value="dark">Dark</option>
                        </Select>
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="forgotPasswordURL">Forgot Password URL</Label>
                        <TextInput
                            color="info"
                            id="forgotPasswordURL"
                            name="forgotPasswordURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/forgot-password"
                            type="text"
                            value={rec.forgotPasswordURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="loginURL">Login URL</Label>
                        <TextInput
                            color="info"
                            id="loginURL"
                            name="loginURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/login"
                            type="text"
                            value={rec.loginURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="payBillURL">Pay Bill URL</Label>
                        <TextInput
                            color="info"
                            id="payBillURL"
                            name="payBillURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/pay-bill"
                            type="text"
                            value={rec.payBillURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="claimsURL">Claims URL</Label>
                        <TextInput
                            color="info"
                            id="claimsURL"
                            name="claimsURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/claims"
                            type="text"
                            value={rec.claimsURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="telematicsURL">Telematics URL</Label>
                        <TextInput
                            color="info"
                            id="telematicsURL"
                            name="telematicsURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/telematics"
                            type="text"
                            value={rec.telematicsURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="roadsideServiceURL">Roadside URL</Label>
                        <TextInput
                            color="info"
                            id="roadsideServiceURL"
                            name="roadsideServiceURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/roadsideServiceURL"
                            type="text"
                            value={rec.roadsideServiceURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="roadsideServicePhone">Roadside Phone</Label>
                        <TextInput
                            color="info"
                            id="roadsideServicePhone"
                            name="roadsideServicePhone"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="https://carrier.com/roadsideServicePhone"
                            type="text"
                            value={rec.roadsideServicePhone}
                        />
                    </div>
                    <div className="mt-2 col-span-6 flex items-center justify-center">
                        <Button color="primary" type="submit">Create Carrier</Button>
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default AddCarrierCard;