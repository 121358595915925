import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Agencies from '../Agencies'
import Agency from '../Agency'
import AgencyAdd from '../AgencyAdd'
import Auth from '../Auth'
import Carrier from '../Carrier'
import CarrierAdd from '../CarrierAdd'
import Carriers from '../Carriers'
import Logout from '../Logout'
import PolicyHolders from '../PolicyHolders'
import PolicyHolderView from '../PolicyHolderView'
import Wrapper from '../Wrapper'

const RouteC = ({ history }) => {
	return (
		<Routes>
			<Route element={<Wrapper />}>
			<Route path="/agencies/add" element={<AgencyAdd />} />
			<Route path="/agency/:agencyId" element={<Agency />} />
			<Route path="/agencies" element={<Agencies history={history} />} />
			<Route path="/commercial/:commercialId/carrier/:groupId" element={<Carrier />} />
			<Route path="/personal/:personalId/commercial/:commercialId" element={<Carrier />} />
			<Route path="/personal/:personalId/carrier/:groupId" element={<Carrier />} />
			<Route path="/carriers/add" element={<CarrierAdd />} />
			<Route path="/carriers" element={<Carriers history={history} />} />
			<Route path="/users" element={<PolicyHolders />} />
			<Route path="/users/:userId" element={<PolicyHolderView />} />
			<Route path="/auth" element={<Auth />} />
			<Route path="/auth/:method" element={<Auth />} />
			<Route path="/logout" element={<Logout />} />
			<Route path='/' element={<Agencies />} />
			</Route>
		</Routes>
	)
}

export default RouteC