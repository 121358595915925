import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CarrierActionsCard from './CarrierActionsCard'
import CarrierInformationCard from './CarrierInformationCard'
import CarrierProfileCard from './CarrierProfileCard'
import model from '../../model'
import { Breadcrumb, Label, ToggleSwitch } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import NavbarSidebarLayout from "../NewNav";

const Carrier = () => {
  let { personalId, commercialId } = useParams();
  const navigate = useNavigate();
  const recPersonal = useSelector(state => model.selector('carrier.recById', { state, id: personalId }))
  const recCommercial = useSelector(state => model.selector('carrier.recById', { state, id: commercialId }))
  const [isCommercial, setIsCommercial] = useState(recPersonal ? false : true);

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('carrier.getById', { carrierId: personalId })
        await model.action('carrier.getById', { carrierId: commercialId })
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })()
  }, [personalId, commercialId])

  if (!recPersonal && !recCommercial) return <div />

  return (
    <NavbarSidebarLayout>
      <>
        <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
          <div className="col-span-full">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/carriers">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Carriers</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/users/list">Carrier</Breadcrumb.Item>
            </Breadcrumb>

            {/* Flex container for heading and toggle */}
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Carrier Information
              </h1>
              <div className="flex items-center">
                <span className="text-base font-medium text-gray-900 dark:text-white">
                  Personal
                </span>
                <div className="ml-3">
                  <Label htmlFor="yearly" className="sr-only">
                    Toggle yearly prices
                  </Label>
                  <ToggleSwitch
                    color="jade"
                    checked={isCommercial}
                    id="isCommercial"
                    label=""
                    name="isCommercial"
                    onChange={() => setIsCommercial(!isCommercial)}
                  />
                </div>
                <span className="text-base font-medium text-gray-500 dark:text-gray-400">
                  Commercial
                </span>
              </div>
            </div>
          </div>

          <div className="col-span-full xl:col-auto">
            <div className="grid grid-cols-1 gap-y-4">
              <CarrierProfileCard
                rec={recPersonal}
                recCommercial={recCommercial}
                isCommercial={isCommercial}
                setIsCommercial={setIsCommercial}
              />
              <CarrierActionsCard />
            </div>
          </div>

          <div className="col-span-2">
            <div className="grid grid-cols-1 gap-y-4">
              <CarrierInformationCard
                rec={recPersonal}
                recCommercial={recCommercial}
                isCommercial={isCommercial}
                setIsCommercial={setIsCommercial}
                navigate={navigate}
              />
            </div>
          </div>
        </div>
      </>
    </NavbarSidebarLayout>
  );
}

export default Carrier;
