import React, { useState } from 'react'
import { Button, Card } from "flowbite-react";
import model from '../../model'

const AgentsLocationsCard = ({ rec }) => {
    const [copiedAgentId, setCopiedAgentId] = useState(null);

    const handleCopyLink = (link, agentId) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setCopiedAgentId(agentId);

                setTimeout(() => {
                    setCopiedAgentId(null);
                }, 3000);
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    return (
        <Card>
            <div className="flow-root">
                <h3 className="text-xl font-bold text-black dark:text-white">Agents & Locations</h3>
                <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {rec && rec.agents.length > 0 ? rec.agents.map((agent, i) => {
                        const agentName = `${agent.firstName} ${agent.lastName}`
                        const agencyAgentId = `${agent.agencyID}-${agent.id}`
                        const agentHqLink = model.helper('agency.hiJackAgentUrl', { agencyId: agent.agencyID, agentId: agent.id })
                        const clientHubLink = model.helper('agency.getAgencyPhaLink', { agencyId: agent.agencyID, agentId: agent.id })

                        return (
                                <li key={i} className="py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-base font-semibold text-gray-900 dark:text-white">
                                                {agentName}
                                            </p>
                                            <p className="truncate text-sm font-normal text-gray-500 dark:text-gray-400">
                                                Agent ID: {agencyAgentId}
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center">
                                            <Button color="primary" className="hover:no-underline" href={agentHqLink} target="_blank" rel="noreferrer">Open HQ</Button>
                                        </div>
                                        <div className="inline-flex items-center">
                                            <Button color="light" onClick={() => handleCopyLink(clientHubLink, agencyAgentId)}>
                                                {copiedAgentId === agencyAgentId ? "Copied!" : "Copy Elite Link"}
                                            </Button>
                                        </div>
                                    </div>
                                </li>
                        )
                    }) : <div className="text-center"> No Agents or Locations found </div>}
                </ul>
            </div>
        </Card>
    );
};

export default AgentsLocationsCard;