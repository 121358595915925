import Navbar from "./NavBar";
import Sidebar from "./SideBar";
import { SidebarProvider, useSidebarContext } from "../../context/SideBarContext";
import classNames from "classnames";

const NewNav =
    function ({ children, isFooter = true }) {
        return (
            <SidebarProvider>
                <Navbar />
                <div className="flex items-start pt-16">
                    <Sidebar />
                    <MainContent isFooter={isFooter}>{children}</MainContent>
                </div>
            </SidebarProvider>
        );
    };

const MainContent = function ({
    children,
    isFooter,
}) {
    const { isOpenOnSmallScreens: isSidebarOpen } = useSidebarContext();

    return (
        <main
            className={classNames(
                "overflow-y-auto relative w-full h-full bg-gray-50 dark:bg-gray-900",
                isSidebarOpen ? "lg:ml-16" : "lg:ml-64"
            )}
        >
            {children}
            {isFooter && (
                <div className="mx-4 mt-4">
                </div>
            )}
        </main>
    );
};

export default NewNav;