import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import model from '../../model'
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import AddCarrierCard from './AddCarrierCard';
import NavbarSidebarLayout from "../NewNav";

const CarrierAdd = () => {
  const navigate = useNavigate()
  const [rec, setRec] = useState({
    id: '',
    name: '',
    division: '',
    groupID: '',
    theme: 'light',

    registerEntryURL: '',
    registerExitURL: '',
    forgotPasswordURL: '',
    loginURL: '',
    payBillURL: '',
    claimsURL: '',
    telematicsURL: '',
    roadsideServiceURL: '',
    roadsideServicePhone: '',
  })

  const handleChange = (name, value) => {
    const newRec = {
      ...rec,
      [name]: value,
    }
    setRec(newRec)
  }

  const handleSubmit = async () => {
    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      const add = {
        id: rec.id || null,
        name: rec.name || null,
        division: rec.division.toLowerCase() || 'personal',
        groupID: rec.groupID || rec.id,
        theme: rec.theme || 'light',

        registerEntryURL: rec.registerEntryURL || null,
        registerExitURL: rec.registerExitURL || null,
        forgotPasswordURL: rec.forgotPasswordURL || null,
        loginURL: rec.loginURL || null,
        payBillURL: rec.payBillURL || null,
        claimsURL: rec.claimsURL || null,
        telematicsURL: rec.telematicsURL || null,
        roadsideServiceURL: rec.roadsideServiceURL || null,
        roadsideServicePhone: rec.roadsideServicePhone || null,
      }

      await model.action('carrier.add', add)
      await model.action('app.setLoading', false)
      const next = `/carriers`
      navigate(next)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <NavbarSidebarLayout>
      <>
        <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
          <div className="col-span-full">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/carriers">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Carriers</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/carriers/add">Create Carrier</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Create Carrier
            </h1>
          </div>
          <div className="col-span-full">
            <div className="grid grid-cols-1 gap-y-4">
              <AddCarrierCard
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                rec={rec}
              />
            </div>
          </div>
        </div>
      </>
    </NavbarSidebarLayout>
  );
}

export default CarrierAdd

