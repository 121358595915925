import React from 'react'
import { Card, Label, TextInput } from "flowbite-react";
import utilStr from '../../utility/str'

const AgencyGeneralInformationCard = ({ rec }) => {
    return (
        <Card>
            <h3 className="mb-4 text-xl font-bold text-black dark:text-white">
                General Information
            </h3>
            <form action="#">
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="first-name">Agency Name</Label>
                        <TextInput
                            color="info"
                            id="agency-name"
                            name="agency-name"
                            value={(rec && rec.name) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="webite">Website</Label>
                        <TextInput
                            color="info"
                            id="website"
                            name="website"
                            value={(rec && rec.website) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="personal-sales-email">Personal Sales Email</Label>
                        <TextInput
                            color="info"
                            id="personal-sales-email"
                            name="personal-sales-email"
                            value={(rec && rec.salesEmail) || ''}
                            type={'email'}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="commercial-sales-email">Commercial Sales Email</Label>
                        <TextInput
                            color="info"
                            id="commercial-sales-email"
                            name="commercial-sales-email"
                            type="email"
                            value={(rec && rec.commercialSalesEmail) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="personal-sales-phone">Personal Sales Phone</Label>
                        <TextInput
                            color="info"
                            id="personal-sales-phone"
                            name="personal-sales-phone"
                            value={(rec && rec.salesPhone && `${utilStr.PhoneFormattedPolicyHolder(rec.salesPhone)}`) || ''}
                            type="tel"
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="commercial-sales-phone">Commercial Sales Phone</Label>
                        <TextInput
                            color="info"
                            id="commercial-sales-phone"
                            name="commercial-sales-phone"
                            value={(rec && rec.commercialSalesPhone && `${utilStr.PhoneFormattedPolicyHolder(rec.commercialSalesPhone)}`) || ''}
                            readOnly
                            type="tel"
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="street-1">Street 1</Label>
                        <TextInput
                            color="info"
                            id="street-1"
                            name="street-1"
                            type="text"
                            value={(rec && rec.address && rec.address.street1) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="street-2">Street 2</Label>
                        <TextInput
                            color="info"
                            id="street-2"
                            name="street-2"
                            type="text"
                            value={(rec && rec.address && rec.address.street2) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="city">City</Label>
                        <TextInput
                            color="info"
                            id="city"
                            name="city"
                            type="text"
                            value={(rec && rec.address && rec.address.city) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="role">State</Label>
                        <TextInput
                            color="info"
                            id="state"
                            name="state"
                            value={(rec && rec.address && rec.address.state) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="zip-code">Zip Code</Label>
                        <TextInput
                            color="info"
                            id="zip-code"
                            name="zip-code"
                            value={(rec && rec.address && rec.address.zip) || ''}
                            readOnly
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="connect-link">Connect Link</Label>
                        <TextInput
                            color="info"
                            id="connect-link"
                            name="connect-link"
                            value={(rec && rec.connectURL) || ''}
                            readOnly
                        />
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default AgencyGeneralInformationCard;