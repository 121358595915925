const flowbiteTheme = {
    badge: {
        root: {
            color: {
                info: "bg-jade-100 text-jade-800 dark:bg-jade-200 dark:text-jade-800 group-hover:bg-jade-200 dark:group-hover:bg-jade-300",
                primary:
                    "bg-jade-100 text-jade-800 dark:bg-jade-200 dark:text-jade-800 group-hover:bg-jade-200 dark:group-hover:bg-jade-300",
            },
            size: {
                xl: "px-3 py-2 text-base rounded-md",
            },
        },
        icon: {
            off: "rounded-full px-2 py-1",
        },
    },
    button: {
        color: {
            gray: "text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-jade-700 :ring-jade-700 focus:text-jade-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700 focus:ring-2",
            info: "text-white bg-jade-700 hover:bg-jade-800 focus:ring-4 focus:ring-jade-300 dark:bg-jade-600 dark:hover:bg-jade-700 dark:focus:ring-jade-800",
            primary:
                "text-white bg-jade-700 hover:bg-jade-800 focus:ring-4 focus:ring-jade-300 dark:bg-jade-600 dark:hover:bg-jade-700 dark:focus:ring-jade-800",
        },
        inner: {
            base: "flex items-center transition-all duration-200",
        },
        outline: {
            color: {
                gray: "border border-gray-200 dark:border-gray-500",
            },
        },
    },
    dropdown: {
        floating: {
            base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
            content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
            target: "w-fit dark:text-white",
        },
        content: "",
    },
    modal: {
        content: {
            inner: "relative rounded-lg bg-white shadow dark:bg-gray-800",
        },
        header: {
            base: "flex items-start justify-between rounded-t px-5 pt-5",
        },
    },
    navbar: {
        root: {
            base: "fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700",
        },
    },
    sidebar: {
        root: {
            base: "flex fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700",
        },
        item: {
            base: "flex items-center justify-center rounded-lg p-2 text-base font-medium text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
        },
        collapse: {
            button:
                "group flex w-full items-center rounded-lg p-2 text-base font-medium text-gray-900 transition duration-75 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700",
        },
    },
    textarea: {
        base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
    },
    textInput: {
        field: {
            input: {
                colors: {
                    info: "border-jade-500 bg-jade-50 text-gray-900 placeholder-gray-700 focus:border-jade-500 focus:ring-jade-500 dark:border-jade-400 dark:bg-jade-100 dark:focus:border-jade-500 dark:focus:ring-jade-500",
                },
                withIcon: {
                    on: "!pl-12",
                },
            },
        },
    },
    toggleSwitch: {
        toggle: {
            checked: {
                color: {
                    jade: "bg-jade-700 border-jade-700",
                },
            },
        },
    },
    select: {
        field: {
                colors: {
                    base: "block w-full text-sm p-2.5 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
                    info: "border-jade-500 bg-jade-50 text-gray-900 placeholder-gray-700 focus:border-jade-500 focus:ring-jade-500 dark:border-jade-400 dark:bg-jade-100 dark:focus:border-jade-500 dark:focus:ring-jade-500",
                },
                withIcon: {
                    on: "!pl-12",
                },
        },
    },
};

export default flowbiteTheme;