import React from 'react'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import { Button, Card, TextInput } from "flowbite-react";

const ConnectAgenciesCard = ({ rec, handleChange, handleSubmit }) => {
    return (
        <Card>
            <h3 className="text-xl font-bold text-black dark:text-white">Connect Agencies</h3>
            <form className="flex flex-col items-center justify-center" onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <TextInput
                    color="info"
                    className="w-full"
                    name="code"
                    onChange={e => onFieldChange(e, handleChange)}
                    placeholder="Start typing an agency code..."
                    value={rec.code}
                />
                <div className="mt-2 items-center">
                    <Button color="primary" type="submit">
                        Connect
                    </Button>
                </div>
            </form>
        </Card>
    );
};

export default ConnectAgenciesCard;