import React from 'react'
import { Button, Table } from "flowbite-react";

const AllCarriersTable = ({ currentCarriers, navigate }) => {
    const listSizeMax = 25

    // Group the carriers by their groupID
    const groupedCarriers = currentCarriers.reduce((groups, carrier) => {
        const groupID = carrier.groupID
        if (!groups[groupID]) {
            groups[groupID] = []
        }
        groups[groupID].push(carrier)
        return groups
    }, {})

    // Create a map with unique carriers per groupID and their IDs separated by division
    const carriersMap = Object.entries(groupedCarriers).reduce((acc, [groupID, group]) => {
        const personalCarriers = group.filter((carrier) => carrier.division === 'personal');
        const commercialCarriers = group.filter((carrier) => carrier.division === 'commercial');
        const carrier = personalCarriers.length > 0 ? personalCarriers[0] : group[0];
        acc[groupID] = {
            carrier,
            personalID: personalCarriers.length > 0 ? personalCarriers[0].id : null,
            commercialID: commercialCarriers.length > 0 ? commercialCarriers[0].id : null,
        };
        return acc;
    }, {});

    const carriers = Object.values(carriersMap);

    return (
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
            <Table.Head className="bg-gray-100 dark:bg-gray-700">
                <Table.HeadCell>Logo</Table.HeadCell>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell>Carrier Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                {carriers ? carriers.map(({ carrier, personalID, commercialID }, i) => {
                    const logo = carrier.logo;
                    const name = carrier.name;
                    const link = personalID && commercialID
                        ? `/personal/${personalID}/commercial/${commercialID}`
                        : personalID
                            ? `/personal/${personalID}/carrier/${carrier.groupID}`
                            : `/commercial/${commercialID}/carrier/${carrier.groupID}`;
                    return (
                        <>
                        {i > listSizeMax ? (
                            <div key={i} className="hidden" />
                        ) : (
                            <Table.Row key={i} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                                    <img
                                        className={`max-h-16 max-w-16  ${carrier && carrier.theme === 'dark' ? 'bg-gray-900' : ''}`}
                                        src={logo}
                                        alt="Carrier Logo"
                                    />
                                </Table.Cell>
                                <Table.Cell className="flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                        <div className="text-base font-semibold text-gray-900 dark:text-white">
                                            {name}
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell>
                                    <div className="flex items-center gap-x-3 whitespace-nowrap">
                                        <Button color="primary" onClick={() => navigate(link)}>Carrier Info</Button>
                                        <Button color="gray" className="hover:no-underline hover:text-jade-700" href={carrier.loginURL} rel="noreferrer">Carrier Login</Button>
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        )}
                        </>
                    )
                })
                    :
                    <Table.Row
                        className="hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                        <Table.Cell colSpan={5} className="p-4 text-base font-normal text-gray-900 dark:text-white">
                            No Carriers found
                        </Table.Cell>
                    </Table.Row>
                }
            </Table.Body>
        </Table>
    );
};

export default AllCarriersTable;