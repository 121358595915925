import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import AgentsLocationsCard from './AgentsLocationsCard';
import AgencyActionsCard from './AgencyActionsCard';
import AgencyGeneralInformationCard from './AgencyGeneralInformationCard';
import AgencyProfileCard from './AgencyProfileCard';
import ClientHubLoginCard from './ClientHubLoginCard';
import NavbarSidebarLayout from "../NewNav";
import model from '../../model'

const Agency = () => {
  let { agencyId } = useParams();
  const navigate = useNavigate();
  const rec = useSelector(state => model.selector('agency.recById', { state, id: agencyId }));
  const [recState, setRecState] = useState({});

  useEffect(() => {
    (async () => {
      try {
        await model.action('app.setLoading', true)
        await model.action('agency.getById', { agencyId })
        await model.action('agency.getFeatureFlags', { agencyID: agencyId })
        await model.action('app.setLoading', false)
      } catch (e) {
        await model.action('app.setLoading', false)
        await model.action('app.setError', e)
      }
    })();
  }, [agencyId]);

  useEffect(() => {
    setRecState({ ...rec })
  }, [rec])

  const handleSubmit = async () => {
    let saveRec = { ...recState }

    Object.keys(saveRec).forEach((i) => {
      if (saveRec[i] === '') saveRec[i] = null
      if (i === 'address') {
        Object.keys(saveRec.address).forEach((j) => {
          if (!saveRec[i][j]) saveRec[i][j] = null
        })
      }
    })

    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      await model.action('agency.save', saveRec)
      await model.action('app.setLoading', false)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <>
      <NavbarSidebarLayout>
        <>
          <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
            <div className="col-span-full">
              <Breadcrumb className="mb-4">
                <Breadcrumb.Item href="/">
                  <div className="flex items-center gap-x-3">
                    <HiHome className="text-xl" />
                    <span className="dark:text-white">Home</span>
                  </div>
                </Breadcrumb.Item>
                <Breadcrumb.Item href={`/agency/${agencyId}`}>Agency</Breadcrumb.Item>
              </Breadcrumb>
              <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                Agency Information
              </h1>
            </div>
            <div className="col-span-full xl:col-auto">
              <div className="grid grid-cols-1 gap-y-4">
                <AgencyProfileCard
                  rec={rec}
                />
                <ClientHubLoginCard
                  rec={rec}
                />
                <AgencyActionsCard
                  rec={rec}
                  agencyId={agencyId}
                  handleSubmit={handleSubmit}
                  recState={recState}
                  setRecState={setRecState}
                  navigate={navigate}
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="grid grid-cols-1 gap-y-4">
                <AgencyGeneralInformationCard
                  rec={rec}
                />
                <AgentsLocationsCard
                  rec={rec}
                />
              </div>
            </div>
          </div>
        </>
      </NavbarSidebarLayout>
    </>
  )
}

export default Agency

