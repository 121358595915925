import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import AgencyListCard from './AgencyListCard'
import ConnectAgenciesCard from './ConnectAgenciesCard'
import GeneralInformationCard from './GeneralInformationCard'
import PurgeModal from '../Modals/PurgeModal'
import model from '../../model'
import UserActionsCard from './UserActionsCard'
import UserProfileCard from './UserProfileCard'
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import NavbarSidebarLayout from "../NewNav";

const PolicyHolderView = () => {
    let { userId } = useParams();
    const userIdNumber = Number(userId);
    const navigate = useNavigate();
    const user = useSelector(state => model.selector('user.rec', state));
    const [agency, setAgency] = useState(null);
    const [modal, setModal] = useState(null);
    const [modalUserId, setModalUserId] = useState(null);
    const [rec, setRec] = useState({
        code: ''
    });
    const [userPageInfo, setUserPageInfo] = useState({})
    const [role, setRole] = useState(null)
    const [agencyList, setAgencyList] = useState([])

    useEffect(() => {
        (async () => {
            try {
                await model.action('app.setLoading', true)
                const getUser = await model.action('user.getRecFromRemote', userId)
                const getRole = await model.action('user.getUserAgencyRole', { userId })
                const getAgency = await model.action('agency.getUserAgencies', { userId })
                setRole(getRole)
                setAgency(getAgency.data[getAgency.data.length - 1])
                setAgencyList(getAgency.data)
                setUserPageInfo(getUser)
                await model.action('app.setLoading', false)
            } catch (e) {
                await model.action('app.setLoading', false)
                await model.action('app.setError', e)
            }
        })();
    }, [userId])

    const handleDelete = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('user.remove', { userId: modalUserId })
            setModal(null)
            setModalUserId(null)
            navigate('/users')
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handlePurge = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('user.purgeUser', { userId: modalUserId })
            setModal(null)
            setModalUserId(null)
            navigate('/users')
            await model.action('app.setLoading', false)
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handleModalClose = () => {
        setModal(null)
        setModalUserId(null)
    }

    const handleChange = (name, value) => {
        const newRec = {
            ...rec,
            [name]: value,
        }
        setRec(newRec);
    }

    const handleSubmit = async () => {
        try {
            await model.action('app.setLoading', true)
            await model.action('agency.connect', { code: rec.code, userId })
            const getAgency = await model.action('agency.getUserAgencies', { userId })
            setAgency(getAgency.data[getAgency.data.length - 1])
            setRec({
                code: ''
            })
            await model.action('app.setLoading', false)
            window.location.reload()
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const disconnectAgency = async ({ agencyID, id }) => {
        try {
            await model.action('app.setLoading', true)
            if (agencyID) {
                await model.action('agency.removeAgencyConnection', { userId, agencyId: agencyID, agentId: id })
            } else {
                await model.action('agency.removeAgencyConnection', { userId, agencyId: id })

            }
            const getAgency = await model.action('agency.getUserAgencies', { userId })
            setAgency(getAgency.data[getAgency.data.length - 1])
            await model.action('app.setLoading', false)
            window.location.reload()
        } catch (e) {
            await model.action('app.setLoading', false)
            await model.action('app.setError', e)
        }
    }

    const handleModalShow = ({ userId }) => {
        setModal('delete');
        setModalUserId(userId);
    }

    const handlePurgeModal = ({ userId }) => {
        setModal('purge');
        setModalUserId(userId);
    }

    const handleLaunchPolicyHolder = (e) => {
        e.stopPropagation()
        window.open(`${model.helper('agency.hiJackHqPolicyHolder', { agency, userId })}`, "_blank")
    }

    return (
        <>
        <NavbarSidebarLayout>
            <>
                <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
                    <div className="col-span-full">
                        <Breadcrumb className="mb-4">
                            <Breadcrumb.Item href="/">
                                <div className="flex items-center gap-x-3">
                                    <HiHome className="text-xl" />
                                    <span className="dark:text-white">Home</span>
                                </div>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="/users">Users</Breadcrumb.Item>
                            <Breadcrumb.Item>Profile</Breadcrumb.Item>
                        </Breadcrumb>
                        <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                            User Information
                        </h1>
                    </div>
                    <div className="col-span-full xl:col-auto">
                        <div className="grid grid-cols-1 gap-y-4">
                            <UserProfileCard
                                userPageInfo={userPageInfo}
                                agency={agency}
                                role={role}
                            />
                            <ConnectAgenciesCard
                                userPageInfo={userPageInfo}
                                rec={rec}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                            />
                            <UserActionsCard
                                agency={agency}
                                handleLaunchPolicyHolder={handleLaunchPolicyHolder}
                                user={user}
                                userIdNumber={userIdNumber}
                                handleModalShow={handleModalShow}
                                userId={userId}
                                handlePurgeModal={handlePurgeModal}
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-1 gap-y-4">
                            <GeneralInformationCard
                                userPageInfo={userPageInfo}
                            />
                            <AgencyListCard
                                agencyList={agencyList}
                                disconnectAgency={disconnectAgency}
                            />
                        </div>
                    </div>
                </div>
            </>
        </NavbarSidebarLayout>
            {modal === 'purge' && <PurgeModal modal={modal} setModal={setModal} handleClose={handleModalClose} handleSubmit={handlePurge} headlineText="Confirm Data Erasure" questionText="Are you sure you want to purge this user?" />}
            {modal === 'delete' && <PurgeModal modal={modal} setModal={setModal} handleClose={handleModalClose} handleSubmit={handleDelete} headlineText="Confirm User Delete" questionText="Are you sure you want to delete this user?" />}
        </>
    )
}

export default PolicyHolderView