import React from 'react'
import { Button, TextInput } from "flowbite-react";
import { onFieldChange, onFormSubmit } from '../../utility/form'

const Verify = ({ handleSubmit, handleChange, rec }) => {
	const authMethodLabel = 'email'
	const authMethodVerb = 'emailed'

	let isValidPin = false
	if (rec.pin.length === 6) {
		isValidPin = true
	}

	return (
		<div className="relative min-h-screen max-h-screen overflow-hidden flex transition-all duration-500 ease-in-out">
			<div className="w-full bg-gray-100 flex items-center justify-center">
				<div className="flex flex-col items-center text-center lg:w-1/2 xl:w-1/3">
					<div className="bg-white mx-3 md:mx-0 px-8 rounded-lg shadow-lg md:px-10 py-10 relative">
						<div className="flex justify-center items-center">
							<img alt="GloveBox" className="mb-4 h-8 mr-2" src="/img/glovebox-logo.svg" />
						</div>
						<p className="text-gray-800 text-3xl leading-9 font-extrabold text-center">
							Verify your {authMethodLabel}
						</p>
						<p className="text-gray-600 text-sm text-center leading-5 font-normal mt-2 ">
							Enter the pin we {authMethodVerb} to you at
						</p>
						<p className="text-gray-600 text-sm text-center leading-5 font-bold break-word  mb-6">
							{rec.email}
						</p>
						<form onSubmit={e => onFormSubmit(e, handleSubmit)}>
							<div className="mb-3">
								<TextInput
									autoFocus
									className="outline-none text-black ring-0"
									color="info"
									autoComplete="one-time-code"
									key="pin"
									name="pin"
									onChange={e => onFieldChange(e, handleChange)}
									placeholder="******"
									type="text"
									style={{ borderColor: '#00D37F', outlineColor: '#00D37F'}}
									value={rec.pin} />
							</div>
							<div className="">
								<Button className="w-full bg-jade-700 text-white" disabled={!isValidPin} color="primary" type="submit">Verify</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Verify
