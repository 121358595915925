import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import model from '../../model'
import AddAgencyCard from './AddAgencyCard'
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import NavbarSidebarLayout from "../NewNav";

const AgencyAdd = () => {
  const navigate = useNavigate()
  const [rec, setRec] = useState({
    name: '',

    theme: 'light',

    customerServicePhone: '',
    customerServiceEmail: '',

    salesPhone: '',
    salesEmail: '',

    // address
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',

    website: '',
    facebook: '',
    instagram: '',
    linkedIn: '',
    twitter: '',
    google: '',
    yelp: '',
    review: '',

    requestQuoteURL: '',
    requestServiceURL: '',
    setAppointmentURL: '',
  });

  const handleChange = (name, value) => {
    setRec({ ...rec, [name]: value });
  }

  const handleSubmit = async () => {

    await model.action('app.setError', null)
    try {
      await model.action('app.setLoading', true)

      const add = {
        enabled: true,

        name: rec.name || null,
        theme: rec.theme || null,

        customerServicePhone: rec.customerServicePhone || null,
        customerServiceEmail: rec.customerServiceEmail || null,

        salesPhone: rec.salesPhone || null,
        salesEmail: rec.salesEmail || null,

        address: {
          street1: rec.street1 || null,
          street2: rec.street2 || null,
          city: rec.city || null,
          state: rec.state || null,
          zip: rec.zip || null,
        },

        website: rec.website || null,
        facebook: rec.facebook || null,
        instagram: rec.instagram || null,
        linkedIn: rec.linkedIn || null,
        twitter: rec.twitter || null,
        google: rec.google || null,
        yelp: rec.yelp || null,
        review: rec.review || null,

        requestQuoteURL: rec.requestQuoteURL || null,
        requestServiceURL: rec.requestServiceURL || null,
        setAppointmentURL: rec.setAppointmentURL || null,
      }

      const added = await model.action('agency.add', add)
      await model.action('app.setLoading', false)

      const next = `/agency/${added.id}`
      navigate(next)
    } catch (e) {
      await model.action('app.setLoading', false)
      await model.action('app.setError', e)
    }
  }

  return (
    <NavbarSidebarLayout>
      <>
        <div className="grid grid-cols-1 gap-y-6 px-4 pt-6 dark:bg-gray-900 xl:grid-cols-3 xl:gap-4">
          <div className="col-span-full">
            <Breadcrumb className="mb-4">
              <Breadcrumb.Item href="/">
                <div className="flex items-center gap-x-3">
                  <HiHome className="text-xl" />
                  <span className="dark:text-white">Home</span>
                </div>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/agencies/add">Create Agency</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              Create Agency
            </h1>
          </div>
          <div className="col-span-full">
            <div className="grid grid-cols-1 gap-y-4">
              <AddAgencyCard
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                rec={rec}
              />
            </div>
          </div>
        </div>
      </>
    </NavbarSidebarLayout>
  );

}

export default AgencyAdd