import {
    Navbar
} from "flowbite-react";
import {
    HiMenuAlt1,
    HiX,
} from "react-icons/hi";
import { useSidebarContext } from "../../context/SideBarContext";
import isSmallScreen from "../../helpers/is-small-screen";

const NavBar = function () {
    const { isOpenOnSmallScreens, isPageWithSidebar, setOpenOnSmallScreens } =
        useSidebarContext();

    return (
        <Navbar fluid>
            <div className="w-full p-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        {isPageWithSidebar && (
                            <button
                            outline-color="primary"
                                onClick={() => setOpenOnSmallScreens(!isOpenOnSmallScreens)}
                                className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline focus:outline-jade-700"
                            >
                                <span className="sr-only">Toggle sidebar</span>
                                {isOpenOnSmallScreens && isSmallScreen() ? (
                                    <HiX className="h-6 w-6" />
                                ) : (
                                    <HiMenuAlt1 className="h-6 w-6" />
                                )}
                            </button>
                        )}
                        <Navbar.Brand href="/">
                            <img
                                alt=""
                                src="/img/glovebox-logo.svg"
                                className="mr-3 h-6 sm:h-8"
                            />
                        </Navbar.Brand>
                    </div>
                </div>
            </div>
        </Navbar>
    );
};

export default NavBar;
