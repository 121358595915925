import React from 'react'
import { onFieldChange, onFormSubmit } from '../../utility/form'
import { Button, Card, Label, Select, TextInput } from "flowbite-react";

const AddAgencyCard = function ({ handleSubmit, handleChange, rec }) {
    return (
        <Card>
            <h3 className="mb-4 text-xl font-bold text-black dark:text-white">
                Agency Details
            </h3>
            <form onSubmit={e => onFormSubmit(e, handleSubmit)}>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="name">Agency Name</Label>
                        <TextInput
                            color="info"
                            id="name"
                            name="name"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Name"
                            type="text"
                            value={rec.name}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="theme">Theme</Label>
                        <Select style={{ borderColor: '#00D37F', outlineColor: '#00D37F'}} id="theme" name="theme" onChange={e => onFieldChange(e, handleChange)} value={rec.theme}>
                            <option value="light">Light</option>
                            <option value="dark">Dark</option>
                        </Select>
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="requestQuoteURL">Request Quote</Label>
                        <TextInput
                            color="info"
                            id="requestQuoteURL"
                            name="requestQuoteURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Request Quote URL"
                            type="text"
                            value={rec.requestQuoteURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="requestServiceURL">Request Service</Label>
                        <TextInput
                            color="info"
                            id="requestServiceURL"
                            name="requestServiceURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Request Service URL"
                            type="text"
                            value={rec.requestServiceURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="setAppointmentURL">Set Appointment URL</Label>
                        <TextInput
                            color="info"
                            id="setAppointmentURL"
                            name="setAppointmentURL"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Set Appointment URL"
                            type="text"
                            value={rec.setAppointmentURL}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="customerServicePhone">Customer Service Phone</Label>
                        <TextInput
                            color="info"
                            id="customerServicePhone"
                            name="customerServicePhone"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="555-555-5555"
                            type="phone"
                            value={rec.customerServicePhone}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="customerServiceEmail">Customer Service Email</Label>
                        <TextInput
                            color="info"
                            id="customerServiceEmail"
                            name="customerServiceEmail"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="jon@company.com"
                            type="email"
                            value={rec.customerServiceEmail}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="salesPhone">Sales Phone</Label>
                        <TextInput
                            color="info"
                            id="salesPhone"
                            name="salesPhone"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="555-555-5555"
                            type="phone"
                            value={rec.salesPhone}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="salesEmail">Sales Email</Label>
                        <TextInput
                            color="info"
                            id="salesEmail"
                            name="salesEmail"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="jon@company.com"
                            type="email"
                            value={rec.salesEmail}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="street1">Street 1</Label>
                        <TextInput
                            color="info"
                            id="street1"
                            name="street1"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Street 1"
                            type="text"
                            value={rec.street1}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="street2">Street 2</Label>
                        <TextInput
                            color="info"
                            id="street2"
                            name="street2"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Street 2"
                            type="text"
                            value={rec.street2}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="city">City</Label>
                        <TextInput
                            color="info"
                            id="city"
                            name="city"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="City"
                            type="text"
                            value={rec.city}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="state">State</Label>
                        <TextInput
                            color="info"
                            id="state"
                            name="state"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="State"
                            type="text"
                            value={rec.state}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="zip">Zip Code</Label>
                        <TextInput
                            color="info"
                            id="zip"
                            name="zip"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Zip"
                            type="text"
                            value={rec.zip}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="website">Website</Label>
                        <TextInput
                            color="info"
                            id="website"
                            name="website"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Website"
                            type="text"
                            value={rec.website}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="facebook">Facebook</Label>
                        <TextInput
                            color="info"
                            id="facebook"
                            name="facebook"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Facebook"
                            type="text"
                            value={rec.facebook}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="instagram">Instagram</Label>
                        <TextInput
                            color="info"
                            id="instagram"
                            name="instagram"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Instagram"
                            type="text"
                            value={rec.instagram}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="linkedIn">LinkedIn</Label>
                        <TextInput
                            color="info"
                            id="linkedIn"
                            name="linkedIn"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="LinkedIn"
                            type="text"
                            value={rec.linkedIn}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="twitter">X (Twitter)</Label>
                        <TextInput
                            color="info"
                            id="twitter"
                            name="twitter"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Twitter"
                            type="text"
                            value={rec.twitter}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="google">Google</Label>
                        <TextInput
                            color="info"
                            id="google"
                            name="google"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Google"
                            type="text"
                            value={rec.google}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="yelp">Yelp</Label>
                        <TextInput
                            color="info"
                            id="yelp"
                            name="yelp"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Yelp"
                            type="text"
                            value={rec.yelp}
                        />
                    </div>
                    <div className="col-span-6 grid grid-cols-1 gap-y-2 sm:col-span-3">
                        <Label htmlFor="zip-code">Review Link</Label>
                        <TextInput
                            color="info"
                            id="zip-code"
                            name="review"
                            onChange={e => onFieldChange(e, handleChange)}
                            placeholder="Review"
                            type="text"
                            value={rec.review}
                        />
                    </div>
                    <div className="mt-2 col-span-6 flex items-center justify-center">
                        <Button color="primary" type="submit">Create Agency</Button>
                    </div>
                </div>
            </form>
        </Card>
    );
};

export default AddAgencyCard;