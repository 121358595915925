import React from 'react'
import { Button, Table } from "flowbite-react";
import Str from '../../utility/str'

const SearchTable = ({ results, hits, hidden, searchQuery, navigate }) => {

    return (
        <>
            <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <Table.Head hidden={hidden} className="bg-gray-100 dark:bg-gray-700">
                    <Table.HeadCell>Name</Table.HeadCell>
                    <Table.HeadCell>User ID</Table.HeadCell>
                    <Table.HeadCell>Verified</Table.HeadCell>
                    <Table.HeadCell>Last Accessed</Table.HeadCell>
                    <Table.HeadCell>User Profile</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                    {results.map((result, index) => {
                        const name = result.user.first_name && result.user.last_name ? `${result.user.first_name} ${result.user.last_name}` : 'N/A';
                        const lastAccess = result.user.last_access ? result.user.last_access : 'N/A';
                        const verified = result.user.verified;
                        const userId = result.user.id;
                        const userContact = result.user.email ? result.user.email : result.user.phone ? result.user.phone : 'N/A';
                        return (
                            <Table.Row key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                <Table.Cell className="mr-12 flex items-center space-x-6 whitespace-nowrap p-4 lg:mr-0">
                                    <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                        <div className="text-base font-semibold text-gray-900 dark:text-white">
                                            {name}
                                        </div>
                                        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                                            {userContact}
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                                    {userId}
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-normal text-gray-900 dark:text-white">
                                    {verified ? (
                                        <div className="flex items-center">
                                            <div className="mr-2 h-2.5 w-2.5 rounded-full bg-green-400"></div>{" "}
                                            Verified
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <div className="mr-2 h-2.5 w-2.5 rounded-full bg-red-600"></div>{" "}
                                            Unverified
                                        </div>
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    <div className="flex items-center gap-x-3 whitespace-nowrap">
                                        {Str.formatDate(lastAccess)}
                                    </div>
                                </Table.Cell>
                                <Table.Cell className="whitespace-nowrap p-4 text-base font-medium text-gray-900 dark:text-white">
                                    <Button color="primary" onClick={() => navigate(`/users/${userId}`)}>
                                        Profile
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </>
    );
};

export default SearchTable;