import React, { useState, useRef } from 'react'
import { Button, Card, TextInput } from "flowbite-react";

const ClientHubLoginCard = ({ rec }) => {
    const [buttonText, setButtonText] = useState("Copy Link");
    const textInputRef = useRef(null);

    const copyToClipboard = () => {
        if (textInputRef.current) {
            navigator.clipboard.writeText(textInputRef.current.value)
                .then(() => {
                    setButtonText("Copied!");

                    setTimeout(() => {
                        setButtonText("Copy Link");
                    }, 3000);
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                });
        }
    };

    const agencyId = rec && rec.id

    return (
        <Card>
            <h3 className="text-xl font-bold text-black dark:text-white">ClientHub Login Link</h3>
            <div className="mb-3 grid grid-cols-1 gap-y-2">
                <TextInput
                    color="info"
                    ref={textInputRef}
                    className="w-full"
                    name="code"
                    value={`https://my.gloveboxapp.com/?agencyId=${agencyId}`}
                    readOnly
                />
            </div>
            <div>
                <div className="flex flex-col items-center">
                    <Button color="primary" onClick={copyToClipboard}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default ClientHubLoginCard;