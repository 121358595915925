import React, { useState } from 'react'
import model from '../../model'
import { Button, Card, TextInput } from "flowbite-react";
import { HiCloudUpload } from "react-icons/hi";

const CarrierProfileCard = ({ rec, recCommercial, isCommercial }) => {
    const [form, setForm] = useState({
        image: '',
        theme: rec?.theme || 'light',
    });

    const [commercialForm, setCommercialForm] = useState({
        image: '',
        theme: recCommercial?.theme || 'light',
    });

    const [show, setShow] = useState(false);

    const handleChange = (e) => {
        const { name, files } = e.target;
        const value = files ? files[0] : e.target.value;

        if (isCommercial) {
            setCommercialForm((prev) => ({ ...prev, [name]: value }));
        } else {
            setForm((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await model.action('app.setError', null);

        try {
            await model.action('app.setLoading', true);

            if (!isCommercial && form.image) {
                await model.action('carrier.uploadImage', { carrierId: rec.id, image: form.image });
            } else if (isCommercial && commercialForm.image) {
                await model.action('carrier.uploadImage', { carrierId: recCommercial.id, image: commercialForm.image });
            }

            await model.action('app.setLoading', false);
            setShow(false);
        } catch (error) {
            await model.action('app.setLoading', false);
            await model.action('app.setError', error);
        }
    };

    const handleFileShow = () => setShow((prev) => !prev);

    const currentRec = isCommercial ? recCommercial : rec;
    const currentTheme = isCommercial ? commercialForm.theme : form.theme;

    return (
        <Card>
            <div className="items-center sm:flex sm:space-x-4 xl:block xl:space-x-0 2xl:flex 2xl:space-x-4">
                <img
                    alt=""
                    src={currentRec?.logo || '/placeholder-logo.png'}
                    className={`mb-4 max-h-32 max-w-44 rounded-lg sm:mb-0 xl:mb-4 2xl:mb-0 ${currentTheme === 'dark' ? 'bg-gray-900' : ''
                        }`}
                />
                <div>
                    <h3 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
                        {currentRec?.name || 'Carrier Name'}
                    </h3>
                    <div className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
                        Carrier Group ID: {currentRec?.groupID || 'N/A'}
                    </div>
                    <Button color="primary" onClick={handleFileShow}>
                        <HiCloudUpload className="mr-2" />
                        Change Logo
                    </Button>
                </div>
            </div>
            {show && (
                <form onSubmit={handleSubmit}>
                    <div className="mt-4">
                        <TextInput
                            color="info"
                            name="image"
                            accept=".jpg,.png,.gif,image/jpeg,image/gif,image/png"
                            onChange={handleChange}
                            type="file"
                        />
                    </div>
                    <div className="flex items-center justify-center mt-4">
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            )}
        </Card>
    );
};

export default CarrierProfileCard;