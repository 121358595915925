import model from '../index'
import constants from './constants'
import store from '../../store';
import config from '../../config';

const agencyCodeSplit = ({ code }) => {
	if (typeof code === 'string' && code.includes('-')) {
		const parts = code.split('-')
		return {
			agencyId: parts[0],
			agentId: parts[1],
		}
	}
	return {
		agencyId: code
	}
}

const getButtonImgLink = () => {
	return `${config.webApp}/img/gb-button-ext.png`
}

const getHijackUrl = (rec) => {
	if (rec && rec.id) {
		return `${config.hqApp}agencies/${rec.id}`
	}

	return ''
}

const getAgencyPhaLink = ({ agencyId, agentId }) => {
	return `${config.clientWebApp}/?agencyId=${agencyId}-${agentId}`
}

const hiJackHqPolicyHolder = ({ agency, userId }) => {
    if (!agency) throw new Error('User is not associated with any agency');
    
    // Make sure both IDs are treated as strings.
    let agencyAgentId = agency.agencyID ? `${String(agency.agencyID)}-${String(agency.id)}` : String(agency.id);

    // Use includes to check for the hyphen.
    if (agencyAgentId.includes('-')) {
        const [agencyId, agentId] = agencyAgentId.split('-');
        return `${config.hqApp}agency-agents/${agencyId}/${agentId}/client/${userId}`;
    }

    return `${config.hqApp}agencies/${agencyAgentId}/client/${userId}`;
}


const hiJackHq = ({ agencyAgentArray }) => {
	if(!agencyAgentArray || !agencyAgentArray.length) throw new Error('User is not associated with any agency');
	
	let agencyAgentId = agencyAgentArray[agencyAgentArray.length - 1];

	agencyAgentArray.forEach(id => {
		if(id.includes('-')) agencyAgentId = id
	})

	if (agencyAgentId.includes('-')) {
		const [agencyId, agentId] = agencyAgentId.split('-')
		return `${config.hqApp}agency-agents/${agencyId}/${agentId}/clients/verified`
	}

	return `${config.hqApp}agencies/${agencyAgentId}/clients/verified`
}

const hiJackAgentUrl = ({ agencyId, agentId }) => {
	return `${config.hqApp}agency-agents/${agencyId}/${agentId}/overview`
}

const toggleAgentFilter = () => {
	const currentFilter = store.getState().agency.filterEnabled;

	store.dispatch({
		type: 'AGENCY_FILTER_SET',
		payload: { filterEnabled: !currentFilter },
	})
}

const getAppLink = ({ agencyId }) => {
	return `${config.clientWebApp}/?agencyId=${agencyId}`
}

model.registerHelpers(constants.model, {
	agencyCodeSplit,
    getAppLink,
    getAgencyPhaLink,
    getButtonImgLink,
    getHijackUrl,
    hiJackAgentUrl,
	hiJackHq,
    hiJackHqPolicyHolder,
    toggleAgentFilter,
})